'use client'
import React, { Suspense } from 'react'
import Script from 'next/script'
import { getCountryKey } from 'helpers/country'
import { traceDistination } from 'store/services/auth/traceability'

const adjustAppToken = {
  jp: 'ivbkp6l8z668',
  ph: 'ce95es6jazuo',
  sg: 'pd865lv7ylfk',
  com: 'w8whhbo5gn40'
}

const env = process.env.ENV
const isProd = env === 'production' // 1. development 2. production

const countryKey = getCountryKey()

export const AdjustSlot = () => {
  const appToken = adjustAppToken[countryKey]
  if (appToken && isProd) {
    return (
      <>
        <Script
          id='adjust-init'
          defer
          dangerouslySetInnerHTML={{
            __html: `
              !(function (t, e, a, r, n, s, d, l, o, i, u) {
              (t.Adjust = t.Adjust || {}), (t.Adjust_q = t.Adjust_q || []);
              for (var c = 0; c < l.length; c++) o(t.Adjust, t.Adjust_q, l[c]);
              (i = e.createElement(a)),
                (u = e.getElementsByTagName(a)[0]),
                (i.async = !0),
                (i.src = "https://cdn.adjust.com/adjust-latest.min.js"),
                (i.onload = function () {
                  for (var e = 0; e < t.Adjust_q.length; e++)
                    t.Adjust[t.Adjust_q[e][0]].apply(t.Adjust, t.Adjust_q[e][1]);
                  t.Adjust_q = [];
                }),
                u.parentNode.insertBefore(i, u);
            })(
              window,
              document,
              "script",
              0,
              0,
              0,
              0,
              [
                "initSdk",
                "getAttribution",
                "getWebUUID",
                "setReferrer",
                "trackEvent",
                "addGlobalCallbackParameters",
                "addGlobalPartnerParameters",
                "removeGlobalCallbackParameter",
                "removeGlobalPartnerParameter",
                "clearGlobalCallbackParameters",
                "clearGlobalPartnerParameters",
                "switchToOfflineMode",
                "switchBackToOnlineMode",
                "stop",
                "restart",
                "gdprForgetMe",
                "disableThirdPartySharing",
                "initSmartBanner",
                "showSmartBanner",
                "hideSmartBanner",
              ],
              function (t, e, a) {
                t[a] = function () {
                  e.push([a, arguments]);
                };
              },
            );
          `
          }}
        />
      </>
    )
  }
}

export const AdjustInitSlot = () => {
  const countryKey = getCountryKey()
  const appToken = adjustAppToken[countryKey]
  if (appToken && isProd) {
    return (
      <Suspense>
        <Script>
          {`window.Adjust.initSdk({
        appToken: '${appToken}',
        environment: 'production'
      });          
      `}
        </Script>
      </Suspense>
    )
  }
}

export const trackAdjustEvent = (eventToken) => {
  if (eventToken && isProd) {
    setTimeout(() => {
      ;(window as any).Adjust?.trackEvent({
        eventToken: eventToken
      })
    }, 3000)
  }
}

type EventTokens = Record<string, string>
export const trackAdjustEventBySite = (eventTokens: EventTokens) => {
  if (!eventTokens || typeof eventTokens !== 'object') {
    console.warn('trackAdjustEventBySite: Invalid eventTokens provided.')
    return
  }
  const eventToken = eventTokens[countryKey]
  trackAdjustEvent(eventToken)
}

export const traceDistinationAbility = () => {
  if (!isProd) return
  const attribution = (window as any)?.Adjust?.getAttribution()
  traceDistination(attribution)
}
