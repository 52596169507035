'use client'
import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useSearchParams } from 'next/navigation'
import { getCountryKey, getLang } from 'helpers/country'
import useMediaQuery from '@mui/material/useMediaQuery'
// import Lottie from 'lottie-react'
import { S3BucketJobseeker } from 'images'
// import findRemoteData from './sources/find_remote_jobs.json'
// import findLocalData from './sources/find_local_jobs.json'
// import findTalentsData from './sources/find_talents.json'
import {
  configKey,
  getCookie,
  setCookie,
  removeCookie,
  userKey,
  redirectUrl
} from 'helpers/cookies'
import linkToHunt from 'helpers/linkToHunt'
import { countryConfig } from 'helpers/country.base'
import { DialogWrap } from './DialogWrap'
import { IconCloseWrap } from './IconCloseWrap'
// import remote_jobs_url from './remote_jobs.png'
// import local_jobs_url from './local_jobs.png'
// import find_talents_url from './find_talents.png'

import remoteJobDefault from './remote-job-default.png'
import remoteJobActive from './remote-job-active.png'
import localJobDefault from './local-job-default.png'
import localJobActive from './local-job-active.png'
import talentsDefault from './talents-find-default.png'
import talentsActive from './talents-find-active.png'

export default function SiteGuideModal({ lang, displayControl = false, onClose = null }) {
  const cfCountryKey = getCookie('cfCountryKey')
  const [currentTab, setCurrentTab] = useState('')
  const [countryKey] = useState(cfCountryKey ?? '')
  const isMobile = useMediaQuery('(max-width: 540px)')
  const [currentLang, setCurrentLang] = useState('')
  const [currentCountry, setCurrentCountry] = useState('')
  const dialogWrapRef = useRef(null)
  // const talentsMotionRef = useRef(null)
  // const remoteMotionRef = useRef(null)
  // const localMotionRef = useRef(null)
  const [displayMotion, setDisplayMotion] = useState(true)
  const hoverDelayRef = useRef(undefined)

  const [countryList] = useState(() =>
    countryConfig.map((item) => ({
      ...item,
      label: item.name,
      value: item.key,
      flag:
        item.key === 'com'
          ? `${S3BucketJobseeker}/flags/flag_inter.png`
          : `${S3BucketJobseeker}/flags/${item.key.toUpperCase()}.png`
    }))
  )

  const searchParams = useSearchParams()

  const referralCode = useRef(searchParams.get('referral_code'))
  const invitedSource = useRef(searchParams.get('invited_source'))
  const [nation, setNation] = useState(() => ({ lang: getLang(), country: getCountryKey() }))

  const handleCurrentTab = (tab) => {
    // switch (tab) {
    //   case 'remote':
    //     localMotionRef.current.currentFrame = 0
    //     localMotionRef.current.stop()
    //     talentsMotionRef.current.currentFrame = 0
    //     talentsMotionRef.current?.stop()
    //     remoteMotionRef.current?.play()
    //     break
    //   case 'local':
    //     remoteMotionRef.current.currentFrame = 0
    //     remoteMotionRef.current.stop()
    //     talentsMotionRef.current.currentFrame = 0
    //     talentsMotionRef.current.stop()
    //     localMotionRef.current.play()
    //     break
    //   case 'talents':
    //     localMotionRef.current.currentFrame = 0
    //     localMotionRef.current.stop()
    //     remoteMotionRef.current.currentFrame = 0
    //     remoteMotionRef.current.stop()
    //     talentsMotionRef.current.play()
    //     break
    //   default:
    //     localMotionRef.current.currentFrame = 0
    //     localMotionRef.current.stop()
    //     remoteMotionRef.current.currentFrame = 0
    //     remoteMotionRef.current.stop()
    //     talentsMotionRef.current.currentFrame = 0
    //     talentsMotionRef.current.stop()
    //     break
    // }
    setCurrentTab(tab)
  }

  const setCurrentTabDelay = (tab) => {
    if (!hoverDelayRef.current) {
      hoverDelayRef.current = setTimeout(() => handleCurrentTab(tab), 100)
    }
  }
  const cancelCurrentTabDelay = () => {
    if (hoverDelayRef.current) {
      clearTimeout(hoverDelayRef.current)
      hoverDelayRef.current = undefined
    }
  }

  const rememberFirstTime = () => {
    if (!displayControl) {
      return false
    }
    setCookie('_siteguideModal', JSON.stringify(true))
  }

  useEffect(() => {
    if (!isMobile) {
      if (!displayControl) {
        setDisplayMotion(true)
        setCurrentTab('local')
      } else {
        setDisplayMotion(true)
        setCurrentTab('')
      }
    } else {
      setCurrentTab('local')
      setDisplayMotion(false)
      // remoteMotionRef.current?.destroy()
      // localMotionRef.current?.destroy()
      // localMotionRef.current?.destroy()
    }
    return () => {
      // remoteMotionRef.current?.destroy()
      // localMotionRef.current?.destroy()
      // localMotionRef.current?.destroy()
    }
  }, [isMobile, displayControl])

  // useEffect(() => {
  //   if (displayMotion && !displayControl) {
  //     localMotionRef.current?.play()
  //   }
  // }, [displayMotion, displayControl])
  useEffect(() => {
    if (displayControl) {
      if (countryKey) {
        setCurrentCountry(countryList.find((item) => item.key === countryKey)?.key || 'com')
        switch (countryKey) {
          case 'ph':
          case 'sg':
            setCurrentLang('en-us')
            break
          case 'hk':
          case 'tw':
            setCurrentLang('zh-tw')
            break
          case 'jp':
            setCurrentLang('ja')
            break
          case 'id':
            setCurrentLang('id')
            break
          case 'tr':
            setCurrentLang('tr')
            break
          case 'my':
            setCurrentLang('ms')
            break
          default:
            setCurrentLang('en-us')
            break
        }
      } else {
        setCurrentCountry('com')
        setCurrentLang('en-us')
      }
    } else {
      setCurrentCountry(getCountryKey() || 'com')
      setCurrentLang(getLang() || 'en-us')
    }
  }, [countryKey, displayControl])

  useEffect(() => {
    setNation((state) => ({
      ...state,
      country: currentCountry,
      lang: currentLang
    }))
  }, [currentLang, currentCountry])

  // useEffect(() => {
  //   const handleCloseDialog = (e) => {
  //     if (dialogWrapRef.current === e.target) {
  //       onClose?.()
  //     }
  //   }
  //   document.addEventListener('click', handleCloseDialog)
  //   return () => {
  //     document.removeEventListener('click', handleCloseDialog)
  //   }
  // }, [])

  const isLocalDev = (url: string) => {
    return url?.includes('localhost') || url.includes('127.0.0.1')
  }

  const handleSwitchNation = () => {
    const { country, lang } = nation
    removeCookie('location')
    const { origin, hostname, pathname, search } = window.location
    const isLocal = isLocalDev(hostname)
    const user = getCookie(userKey)
    const sessionid = getCookie('sessionid')
    let query = `/${lang}`
    const prefix = process.env.NEXT_PUBLIC_URL_PREFIX || ''

    const newOrigin = `https://${prefix}${
      countryList.find((item) => item.key === currentCountry)?.url
    }`
    const referralCodeParams = referralCode.current ? `&referral_code=${referralCode.current}` : ''
    const invitedSourceParams = invitedSource.current
      ? `&invited_source=${invitedSource.current}`
      : ''

    if (origin.includes(newOrigin)) {
      // only language changed
      // the pathname is likely "/en-us/get-started"
      let restPath = pathname.split('/').slice(2).join('/')
      restPath = restPath ? `/${restPath}` : ''
      // store this in cookies. then the others link request server can take it to server
      setCookie(configKey, `${country}_${lang}`)
      window.location.href = newOrigin + query + restPath + location.search
      return
    }

    if (!isLocal && sessionid) {
      query +=
        `/changeLocale?` +
        `&country=${country}` +
        `&${userKey}=${JSON.stringify(user)}` +
        `&${'sessionid'}=${sessionid}` +
        `&${redirectUrl}=${pathname.split('/').slice(2).join('/')}
        `
      query += referralCodeParams
      query += invitedSourceParams
    } else {
      query += '/' + pathname.split('/').slice(2).join('/') + search ? '?' + search.slice(1) : ''
    }
    window.location.href = newOrigin + query
    // + '?' + referralCodeParams + invitedSourceParams
  }

  return (
    <DialogWrap ref={dialogWrapRef}>
      <div className='popper'>
        <div className='popper-dialog'>
          {isMobile ? <IconCloseWrap isMobile={isMobile} onClose={() => onClose?.()} /> : null}
          <div className='tabs'>
            <span
              className={currentTab === 'remote' ? 'active' : ''}
              onClick={() => setCurrentTab('remote')}
            >
              {lang.siteGuideModal.remoteJobs}
            </span>
            <span
              className={currentTab === 'local' ? 'active' : ''}
              onClick={() => setCurrentTab('local')}
            >
              {lang.siteGuideModal.localJobs}
            </span>
            <span
              className={currentTab === 'talents' ? 'active' : ''}
              onClick={() => setCurrentTab('talents')}
            >
              {lang.siteGuideModal.findTalents}
            </span>
          </div>
          <div
            className={classNames({
              item: true,
              current: currentTab === 'local'
            })}
            onMouseOver={() => setCurrentTabDelay('local')}
            onMouseOut={cancelCurrentTabDelay}
          >
            <div className='motion-image' style={{ borderRadius: '16px 0px 0px 16px' }}>
              <img src={currentTab === 'local' ? localJobActive.src : localJobDefault.src} alt='' />
            </div>
            <div className='image'>
              <img src={localJobDefault.src} alt='' />
            </div>
            <div className={classNames('content', { active: currentTab === 'local' })}>
              <div className='text'></div>

              {currentTab === 'local' ? (
                <div className='button'>
                  <button
                    onClick={() => {
                      if (currentCountry === getCountryKey()) {
                        setCookie(configKey, `${currentCountry}_${currentLang}`)
                      }
                      rememberFirstTime()
                      handleSwitchNation()
                    }}
                  >
                    {lang.siteGuideModal.localJobs}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames({
              item: true,
              current: currentTab === 'remote'
            })}
            onMouseOver={() => setCurrentTabDelay('remote')}
            onMouseOut={cancelCurrentTabDelay}
          >
            <div className='motion-image'>
              <img
                src={currentTab === 'remote' ? remoteJobActive.src : remoteJobDefault.src}
                alt=''
              />
            </div>

            <div className='image'>
              <img src={remoteJobDefault.src} alt='' />
            </div>
            <div className={classNames('content', { active: currentTab === 'remote' })}>
              <div className='text'></div>
              {currentTab === 'remote' ? (
                <div className='button'>
                  <button
                    onClick={() => {
                      rememberFirstTime()
                      location.href = `${location.origin}/${location.pathname
                        .split('/')
                        .slice(0, 2)
                        .join('/')}/remote-jobs${location.search}`
                    }}
                  >
                    {lang.siteGuideModal.remoteJobs}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames({
              item: true,
              current: currentTab === 'talents'
            })}
            onMouseOver={() => setCurrentTabDelay('talents')}
            onMouseOut={cancelCurrentTabDelay}
          >
            <div className='motion-image' style={{ borderRadius: '0px 16px 16px 0px' }}>
              <img src={currentTab === 'talents' ? talentsActive.src : talentsDefault.src} alt='' />
            </div>
            <div className='image'>
              <img src={talentsDefault.src} alt='' />
            </div>
            <div className={classNames('content', { active: currentTab === 'talents' })}>
              <div className='text'></div>
              {currentTab === 'talents' ? (
                <div className='button'>
                  <button
                    onClick={() => {
                      rememberFirstTime()
                      location.href = linkToHunt('')
                    }}
                  >
                    {lang.siteGuideModal.startHiring}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {!isMobile ? <IconCloseWrap isMobile={isMobile} onClose={() => onClose?.()} /> : null}
      </div>
    </DialogWrap>
  )
}
